<template>
    <div>
        <v-carousel v-model="slide">
            <v-carousel-item src="../../assets/img/slider/slider-1.jpg">
                <div class="text-center" style="height: 100%; padding-top: 100px; background: radial-gradient(transparent, black), rgba(0,0,0,0.5);">
                    <div style="font-size: 20px;" class="mb-5">Du brauchst</div>
                    <div style="font-size: 40px;" class="mb-5">Leistung und Qualität</div>
                    <div style="font-size: 20px;" class="mb-5">Miete jetzt deinen</div>
                    <v-btn color="primary" class="mt-5" x-large :to="{ name: 'rootserver' }">Rootserver</v-btn><v-btn color="primary" class="ml-5 mt-5" x-large :to="{ name: 'dedicated-server' }">Dedicated Server</v-btn>
                </div>
            </v-carousel-item>
            <v-carousel-item src="../../assets/img/slider/slider-2.jpg">
                <div class="text-center" style="height: 100%; padding-top: 100px; background: radial-gradient(transparent, black), rgba(0,0,0,0.5);">
                    <div style="font-size: 20px;" class="mb-5">Du brauchst einen</div>
                    <div style="font-size: 40px;" class="mb-5">Teamspeak Server</div>
                    <div style="font-size: 20px;" class="mb-5">Zum Zocken oder für dein Netzwerk</div>
                    <v-btn color="primary" class="mt-5" x-large :to="{ name: 'teamspeak-server' }">Starte jetzt</v-btn>
                </div>
            </v-carousel-item>
        </v-carousel>
        <div class="px-page mt-5 mb-5">
            <v-row class="mt-5 mb-5">
                <v-col md="12" class="mt-5 mb-5">
                    <h3 class="text-center" style="font-size: 30px;">Das sagen unsere Kunden... Hallo Laurin</h3>
                </v-col>
                <v-col md="4" v-for="testimonial in $store.state.testimonials" :key="testimonial.id">
                    <v-card style="min-height: 350px;">
                        <v-card-text class="text-center" style="font-size: 16px;">
                            <cite>"{{ testimonial.title }}"</cite><br><br>
                            <strong>{{ testimonial.author }}</strong><br>
                            <small>am {{ dateFormat(testimonial.created_at, 'dd.MM.yyyy') }}</small>
                            <small v-if="testimonial.source == 'TRUSTPILOT'"> auf <a target="_blank" href="https://de.trustpilot.com/review/xeonserv.de">TrustPilot</a></small>
                            <small v-if="testimonial.source == 'SUPPORT_TICKET'"> im Support</small>
                            <v-rating half-increments :value="testimonial.rating / 2" color="orange" class="mt-2 mb-2" />
                            <cite>"{{ toggledTestimonials.includes(testimonial.id) ? testimonial.text : testimonial.text.substr(0, 250) }}" <span v-if="testimonial.text.length> 250" ><a @click="toggleTestimonial(testimonial.id)">{{ toggledTestimonials.includes(testimonial.id) ? 'weniger' : 'weiterlesen' }}</a></span></cite>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import dateFormat from '../../util/dateFormat'

export default {
    data() {
        return {
            slide: 0,
            toggledTestimonials: []
        }
    },
    methods: {
        dateFormat,
        toggleTestimonial(id) {
            if(this.toggledTestimonials.includes(id)) {
                this.toggledTestimonials = this.toggledTestimonials.filter(t => t != id)
            } else {
                this.toggledTestimonials.push(id)
            }
        }
    }
}
</script>